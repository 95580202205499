import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Slices from "../components/Slices"
import ParseSEOData from "../components/ParseSEOData"
import { defaultLang } from "../../prismic-configuration"
export const query = graphql`
  query {
    prismic {
      allPages(uid: "index") {
        edges {
          node {
            title
            meta_description
            meta_title
            share_image
            _meta {
              type
              uid
              lang
            }
            body {
              ... on PRISMIC_PageBodyHero {
                type
                label
                primary {
                  heading
                  image
                  cta_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Samples {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Shop {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Collection {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Case {
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                  cta_text
                }
              }
              ... on PRISMIC_PageBodyReview {
                type
                label
                primary {
                  reviews_title
                }
                fields {
                  review {
                    ... on PRISMIC_Review {
                      title
                      author
                      date
                      review
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyCollections {
                type
                label
              }
              ... on PRISMIC_PageBodyIntro {
                type
                label
                primary {
                  intro_cta {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Samples {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Shop {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Collection {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Case {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                  intro_text
                  intro_title
                }
                fields {
                  feature_description
                  feature_icon
                  feature_title
                  feature_link {
                    _linkType
                  }
                }
              }
              ... on PRISMIC_PageBodyFeature {
                type
                label
                primary {
                  feature_title
                  feature_text
                  feature_image
                  feature_heading
                  feature_imageSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                  feature_cta_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Samples {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Shop {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Collection {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Case {
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                  feature_cta_text
                }
              }
            }
          }
        }
      }
    }
  }
`

const Index = ({ data }) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const doc = data.prismic.allPages.edges.find(pageData => {
          return pageData.node._meta.lang === defaultLang;
        })
        if (!doc) return null
        return (
          <>
            <ParseSEOData data={doc.node} />
            <Slices allSlices={doc.node.body} lang={defaultLang} />
          </>
        )
      }}
    />
  )
}

Index.query = query

export default Index
